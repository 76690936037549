import { shortListPageJs } from "./shortList/shortListPage.js";
import { customCompanySearchAutoComplete } from "./companySearchAutoComplete.js";

export var offeringPageJs = {
    searchSubOfferingData: function (element, offering, offeringIsMain) {
        var $spinner = $("#spinnerSearch");
        if ($spinner) {
            $spinner.show();
        }

        var linksSuboffering = document.getElementsByClassName('link-suboffering-page active');
        for (var i = 0; i < linksSuboffering.length; i++) {
            var subofferingLink = linksSuboffering[i];
            if (subofferingLink.innerHTML) {
                var innerHTML = subofferingLink.innerHTML;
                subofferingLink.innerHTML = offeringPageJs.removeNumbersInBrackets(innerHTML);
            }
            subofferingLink.classList.remove('active');
            // Add subofferingLink class if it is missing after removing the active class.
            // Prevents the offering from misalignment because of missing margins.
            if (!subofferingLink.classList.contains("subofferingLink") &&
                !subofferingLink.classList.contains("mainsign") &&
                !subofferingLink.classList.contains("active")) {
                  subofferingLink.classList.add('subofferingLink');
            }
        }

        element.classList.add('active');

        // Remove subofferingLink class if the link is active.
        // The active class adds the margins already so no need to have it twice.
        if (element.classList.contains("active") &&
            element.classList.contains("subofferingLink")) {
              element.classList.remove("subofferingLink");
            }

        var linksMainoffering = document.getElementsByClassName('mainsign');
        for (var i = 0; i < linksMainoffering.length; i++) {
            var linkMainoffering = linksMainoffering[i];
            if (!linkMainoffering.classList.contains('main')) {
                linkMainoffering.classList.add('main');
            }
        }

        if (element.classList.contains('main')) {
            element.classList.remove('main');
        }

        var query = "";
        if (offering != '' && typeof offering != "undefined") {
            query += "?s=" + offering;
        }

        $.ajax({
            url: offeringPageOptions.subOfferingCompaniesUrl + query,
            type: 'POST'
        })
            .done(function (data) {
                $("#sub-offering-companies").html(data);

                var $data = $(data); // Convert data to jQuery object
                var companiesCount = $data.find('#offering-page-companies-count').val();
                element.innerHTML = element.innerHTML + " (" + companiesCount + ")";

                // Bind the shortlist again in order to activate add to shortlist and remove shortlist buttons.
                // When pressing the sub offering buttons on the left side of offering page.
                shortListPageJs.bindShortList();

                // bind / reactivate the custom company search autocomplete.
                customCompanySearchAutoComplete();

                $.ajax({
                    url: offeringPageOptions.subOfferingDataUrl + query,
                    type: 'POST'
                })
                    .done(function (data) {
                        $("#sub-offering-data").html(data);

                        var exploreLink = document.getElementById('btn-explore');
                        var newPartLink = "#offeringCompanies";
                        if (offering && offering > 0) {
                            newPartLink = "?s=" + offering + newPartLink;
                            exploreLink.setAttribute("data-s", offering);
                        }
                        exploreLink.href = newPartLink;
                    })
                    .fail(function (error) {
                        console.error('Error:', error);

                        if ($spinner) {
                            $spinner.hide();
                        }
                    });

            })
            .fail(function (error) {
                console.error('Error:', error);
                if ($spinner) {
                    $spinner.hide();
                }
            });
    },
    removeNumbersInBrackets: function (inputString) {
        return inputString.replace(/\(\d+\)/g, '');
    }
}

document.addEventListener('DOMContentLoaded', function () {
    var links = document.getElementsByClassName('link-suboffering-page');
    for (var i = 0; i < links.length; i++) {
        links[i].addEventListener('click', function (event) {
            event.preventDefault(); // Prevent the default action (in this case, auto scroll to top)            
        });
    }
});