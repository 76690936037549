// Include jQuery UI for the autocomplete plugin
import "jquery-ui-dist/jquery-ui";

export function customCompanySearchAutoComplete() {
    // Custom autocomplete with categories for company search
    $.widget("custom.catcomplete", $.ui.autocomplete, {
        _create: function () {
            this._super();
            this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
        },
        _renderMenu: function (ul, items) {
            var that = this,
                currentCategory = "";

            // Render items along with category titles
            $.each(items, function (index, item) {
                if (item.resultCategory != currentCategory) {
                    ul.append("<li class='ui-autocomplete-category'>" + item.resultCategory + "</li>");
                    currentCategory = item.resultCategory;
                }

                that._renderItemData(ul, item);
            });
        },
        _renderItem: function (ul, item) {
            // Highlighting
            var newText = String(item.value).replace(
                new RegExp(this.term, "gi"),
                "<strong class='ui-state-highlight'>$&</strong>");

            return $("<li></li>")
                .data("item.ui-autocomplete-item", item)
                .append("<div>" + newText + "</div>")
                .appendTo(ul);
        }
    });

    $("#company-search-query").catcomplete({
        //delay: 0,
        minLength: 3,
        source: function (request, response) {
            $.ajax({
                url: companySearchOptions.quickSearchUrl,
                dataType: "json",
                data: {
                    q: request.term
                },
                success: function (data) {
                    response($.map(data, function (value, key) {
                        return {
                            label: value.Result,
                            value: value.Result,
                            resultCategory: value.Category,
                            pageUrl: value.PageUrl
                        }
                    }));
                }
            });
        },
        select: function (event, ui) {
            if (ui.item.resultCategory == 'Company' || ui.item.resultCategory == 'Offering') {
                // Navigate directly to the result company or offering
                var pageUrl = ui.item.pageUrl;
                window.location.href = pageUrl;
            }
            else {
                // Initiate search with the specific term
                var searchTerm = ui.item.value;

                // Add double quotes if the term contains white-space(s)
                if (searchTerm.indexOf(' ') >= 0) {
                    searchTerm = '"' + searchTerm + '"';
                }

                $("#company-search-query").val(searchTerm);
                $(".search-field form button").trigger("click");
            }
            return false;
        }
    });
}

$(function () {
    // Only run on company search pages
    if (!$('.offering-page, .short-list-company-page, .company-search-page').length) {
        return;
    }
    customCompanySearchAutoComplete();
})